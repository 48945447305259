body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Overlock";
  /* 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  max-width: 100% !important;
  overflow-x: hidden !important;
  /* background-color: #022911 !important; */
  background-size: 100%;
  background-repeat: no-repeat;
}


code {
  font-family: "Overlock", serif;
}

.player-wrapper {
  position: fixed;
  min-height: 101vh !important;
  min-width: 100vw !important;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  top: 0px;
  left:0;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .react-player {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
  .destopText {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .react-player {
    position: fixed;
    top: 0;
    left: 0vw;
    z-index: -1;
  }
  .destopText {
    margin-top: 20vh !important;
    position: relative;
  }
  .homeMobile {
    position: relative;
    top: -20vh;
    width: 100%;
    height: 101vh !important;
    z-index: -2;
  }
}

.bgHome {
  background-color: rgba(0, 0, 0, 0.5);
}

.homePic {
  background-clip: border-box;
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(#e89e00c8, #00000080),
  url('/src/src/renders/bg.jpg');
    /* url(https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/48/nie-tylko-smok-wawelski-zwiedzanie-krakowa-szlakiem-legend.jpeg); */
    /* background-image: linear-gradient(#0229113a, #022911),
    url(https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/48/nie-tylko-smok-wawelski-zwiedzanie-krakowa-szlakiem-legend.jpeg); */

  height: 101vh !important;
z-index: -7;
  left: 0;
  position: absolute;
}
.onasPic {
  background-clip: border-box;
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(2, 41, 17, 0.227), #022911),
    url(https://www.national-geographic.pl/media/cache/slider_big/uploads/media/default/0014/48/nie-tylko-smok-wawelski-zwiedzanie-krakowa-szlakiem-legend.jpeg);

  height: 100px !important;

  left: 0;
  position: absolute;
}

/* :root .safari_only {

  .react-player {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
  }

} */

/* @media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .react-player {
      visibility: hidden;
      clear: both;
      float: left;
      display: none;
    }

}} */

.iframe{
  width: 100vw !important;
}

.fixed{
  position: fixed !important;
}

.homeText{
  color: white;
  font-size: larger;
  
}
.samllText{
  font-size: smaller;
}
.picBG{
  box-shadow: 55px 55px 1px;
color: #022911;
margin-bottom: 55px;
}